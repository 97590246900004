$brand-color: #046865;
$dark-brand-color: #023735;
$base-color: #fefefa;
$main-color: #07b2ad;
$dark-sub-color: #303030;
$sub-color: #f5f5f5;
$accent-color: #92140c;
$copyright-color: #222527;

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 800px)";
$large-desktop: "(min-width: 1000px)";
